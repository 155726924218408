import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"

import { CheckList, Footer, Header } from "../../components"
import { ImageCircle } from "../../components/image-circle"
import { JuryNote } from "../../components/jury-note"
import { Quote } from "../../elements"
import ReviewFlow from "../../elements/review-flow"
import { BodyText, Display, Highlight, Lead, Title } from "../../identity"
import { Emphasis } from "../../identity/typography/emphasis"
import { List } from "../../identity/typography/list"
import TechLogoCloud from "../../images/migipedia/tech-logo-cloud.svg"
import LogoCloud from "../../images/migipedia/logo-cloud.svg"
import Cumulus from "../../images/migipedia/cumulus.svg"
import GatsbyImage from "gatsby-image"

import { Container } from "../../layout"
import { theme } from "../../theme"

const Feature = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[5]};
  margin: 0 0 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Max = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
`

const FeatureBox = styled.div`
  padding: 3rem;
`

const TwoColumns = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const SmallBodyText = styled(BodyText)`
  font-size: 0.9rem;
`

const Scribble = styled.div`
  padding-left: 2rem;
  min-width: 260px;
`

const ImageContainer = styled.div`
  padding: 1rem 0;
`

export default () => {
  const {
    snack: {
      childImageSharp: { fixed: snack },
    },
    lgp: {
      childImageSharp: { fluid: lgp },
    },
    reviewFlow: {
      childImageSharp: { fluid: reviewFlow },
    },
    bike: {
      childImageSharp: { fixed: bike },
    },
    discussing: {
      childImageSharp: { fixed: discussing },
    },
    recommender: {
      childImageSharp: { fluid: recommender },
    },
    stairs: {
      childImageSharp: { fixed: stairs },
    },
    philipp: {
      childImageSharp: { fixed: philipp },
    },
    logoCloud: {
      childImageSharp: { fluid: logoCloud },
    },
  } = useStaticQuery(graphql`
    query {
      snack: file(relativePath: { eq: "migipedia/02_snack_001.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lgp: file(relativePath: { eq: "migipedia/lgp.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      discussing: file(
        relativePath: { eq: "migipedia/01_diskutieren_007.jpg" }
      ) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recommender: file(
        relativePath: { eq: "migipedia/phone-landingpage-top.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewFlow: file(relativePath: { eq: "migipedia/review-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bike: file(relativePath: { eq: "migipedia/03_bike_016.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      couch: file(relativePath: { eq: "migipedia/05_couch_010.jpg" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stairs: file(relativePath: { eq: "migipedia/04_kitchen_012.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      philipp: file(relativePath: { eq: "migipedia/philipp-buehler.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCloud: file(relativePath: { eq: "migipedia/logo-cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>smartive — Eingaben Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header
          heading="Eingabe Best of Swiss Web 2021"
          subheading="Kategorie Technology"
        />
        <Container>
          <Display>
            <Highlight>MIGIPEDIA</Highlight> – 10 Jahre User im Mittelpunkt
          </Display>
          <Lead>
            Seit 2010 stehen bei Migipedia – der Community der Migros –
            Kundinnen und Kunden im Mittelpunkt. Die Ansprüche der User an die
            Plattform haben sich in dieser Zeit verständlicherweise immer wieder
            verändert. Mit dem Migipedia-Relaunch von 2020 wird diesen
            Veränderungen einmal mehr Rechnung getragen. Mit Erfolg: Die
            Community ist heute so lebendig wie nie zuvor.
          </Lead>
        </Container>
        <ImageCircle
          images={{
            left: stairs,
            right: snack,
            center: recommender,
          }}
        />
        <Container>
          <Lead>
            Gib mir Bilder. Gross und gestochen scharf. Überrasch mich. Zeig mir
            etwas, das mich interessiert. Mach mir Produktvorschläge, die für
            mich relevant sind. Lass mich klicken. Aber schön verpackt bitte.
            In, sagen wir, zweieinhalb Sekunden. Und dann bitte so, dass alles
            perfekt auf einem Smartphone funktioniert. Die Ansprüche an eine
            moderne Website sind hoch. Wie also eine interaktive Plattform
            bauen, die den Nutzern Relevantes präsentiert, zum Mitmachen anregt,
            chic daherkommt und schnell ist?
          </Lead>
        </Container>
        <Container>
          <TechLogoCloud />
        </Container>
        <Container>
          <CheckList
            items={[
              "Tech-Stack auf der Höhe der Zeit: Next.js, React, Tailwind, GraphQL",
              "Optimale Kombination von dynamisch und statisch auf Seitenbasis (SSG, SSR, CSR)",
              "Performance hat Priorität (Datensparsamkeit, Caching, Chunking, Prefetching)",
              "Best Practices verankert durch Testing, Lighthouse Score und Monitoring",
              "Alles unter einem Dach – dank Widgets, Microsites und Verwendung von Microservices",
              "Recommender ziehen für jeden Kunden das Beste aus Big Data",
            ]}
          />
        </Container>
        <Quote
          author="Philipp Bühler, Senior Product Owner Migipedia & Brand"
          photo={philipp}
        >
          &laquo;Der Einsatz einer für uns massgeschneiderten, modernen und
          performanten Technologie ist sozusagen das Lebenselixier und
          vielleicht der wichtigste Grund dafür, dass die Community heute so
          aktiv ist wie nie zuvor.&raquo;
        </Quote>
        <Container>
          <Title number="01">
            Das Ziel: <Highlight>Schneller</Highlight> am Start
          </Title>
          <BodyText>
            Um so schnell wie möglich am Start zu sein, holt Migipedia das Beste
            aus den besten Techniken heraus.
          </BodyText>
          <BodyText>
            Pfeiler 1: Ein robuster Tech-Stack auf der Höhe der Zeit. Nicht weil
            modern, sondern wegen der Vorteile: Next.js bringt den optimalen Mix
            von statisch und dynamisch, mit React lassen sich komplexe
            Interaktionen gestalten, Tailwind sorgt für ein einheitliches
            Erscheinungsbild bei minimaler CSS-Grösse, und GraphQL erlaubt eine
            flexible und sparsame Datenübertragung.
          </BodyText>
          <BodyText>
            Pfeiler 2: Best Practices. So verankert, dass sie konsequent
            eingehalten werden. Den Grundstein legen automatisiertes Deployment
            und Testing. Cypress macht umfassendes Testing so komfortabel wie
            möglich. Und Lighthouse zeigt die rote Karte, wenn ein kritischer
            Wert unterschritten wird.
          </BodyText>
          <BodyText>
            Aber auch die Details sollen stimmen:
            <List listType="unordered">
              <BodyText>
                Bilder werden in verschiedenen Formaten (webp, jpeg) und Grössen
                angeboten, sodass der Browser die beste Option wählen kann –
                klein auf Mobile, gross auf Desktop, immer scharf.
              </BodyText>
              <BodyText>
                Chunking: Next.js ermöglicht nicht nur das Ausliefern von vorab
                generierten Seiten, sondern auch das Aufteilen von JavaScript in
                Chunks, damit prioritär geladen und ausgeführt wird, was zuerst
                benötigt wird. Was zur Folge hat, dass die Seite schneller für
                User-Inputs bereit ist.
              </BodyText>
              <BodyText>
                Prefetching: Wenn ein interner Link ins Sichtfeld kommt, wird im
                Hintergrund der Inhalt vorgeladen, damit im Fall eines Klicks
                nicht von null geladen werden muss.
              </BodyText>
            </List>
          </BodyText>
        </Container>

        <Feature>
          <Max>
            <TwoColumns>
              <FeatureBox>
                <Title>Dynamisch und statisch: Endlich beste Freunde</Title>
                <SmallBodyText>
                  Serverseitig generiertes HTML bringt Inhalte schnell auf den
                  Bildschirm. Was nicht zuoberst ist, kann aber gut auf dem
                  Client gerendert werden. Und natürlich ändern sich Inhalte
                  unterschiedlich häufig. Mit Next.js kann auf Seitenbasis die
                  passende Strategie gewählt werden:
                </SmallBodyText>
                <List listType="unordered">
                  <SmallBodyText>
                    Inhalte, die sich (fast) nie ändern, werden beim Deployment
                    generiert, allzeit bereit zur Auslieferung (Static Site
                    Generation).
                  </SmallBodyText>
                  <SmallBodyText>
                    Wo die Änderung sofort sichtbar sein soll, etwa auf
                    Produktseiten mit Kaufpreis, wird die Seite bei Abruf auf
                    dem Server generiert (Server Side Rendering).
                  </SmallBodyText>
                  <SmallBodyText>
                    Ob ein Teaser 56 oder 57 neue Fragen anzeigt, ist nicht
                    kritisch. Änderungen sollten zeitnah dargestellt werden.
                    Aber mit Blick auf die Geschwindigkeit ist eine bereits
                    generierte Seite einer topaktuellen vorzuziehen. Hier kommt
                    ein Hybrid von SSR und SSG zum Zug: Vorgenerierte Seiten
                    werden ohne Vorlauf ausgeliefert, wobei regelmässig
                    überprüft wird, ob es aktuellere Daten gibt (Incremental
                    Static Generation).
                  </SmallBodyText>
                </List>
                <SmallBodyText>
                  Wo die Darstellung vom Client abhängt (insbesondere Funktionen
                  mit Login), kann man sich den Umweg über den Server ersparen –
                  das Abfragen und Darstellen der Daten wird dem Client
                  überlassen. Damit steht für jeden Fall das geeignete Werkzeug
                  bereit. Für die Nutzer bedeutet das ein schnelleres und
                  angenehmeres Erlebnis.
                </SmallBodyText>
              </FeatureBox>

              <FeatureBox>
                <Title>Lighthouse & Web Vitals: Messbares messen</Title>
                <SmallBodyText>
                  Gern verliert man das Wesentliche aus den Augen. User wollen
                  Bilder, Interaktionsmöglichkeiten, Empfehlungen, um Zeit zu
                  sparen. Aber jedes Feature verursacht weitere Kilobytes im
                  Päckli, das dem User geschickt wird. Deshalb sollte immer auch
                  die Performance beachtet werden, welche Bilder oder
                  zusätzliche Features mit sich bringen.
                </SmallBodyText>
                <TwoColumns>
                  <div>
                    <SmallBodyText>
                      Während der Entwicklung übernimmt der in die Pipeline
                      integrierte Lighthouse Score diese Funktion.
                      Entscheidender sind aber Messungen in der echten Welt,
                      direkt bei den Usern. Hier kommen Web Vitals ins Spiel.
                      Daten zur Performance werden bei den Usern erhoben: Wie
                      schnell ist die Seite auf dem Bildschirm? Springen
                      Elemente umher? Wie lang geht es, bis man klicken kann?
                    </SmallBodyText>
                    <SmallBodyText>
                      Nicht alles ist messbar. Aber Kennzahlen helfen, das
                      Wesentliche im Blick zu behalten. Nur so wissen wir, ob
                      die Optimierungen wirklich bei den Usern wie gedacht
                      ankommen.
                    </SmallBodyText>
                  </div>
                  <Scribble>
                    <GatsbyImage fluid={lgp} />
                  </Scribble>
                </TwoColumns>
              </FeatureBox>
            </TwoColumns>
          </Max>
        </Feature>

        <Container>
          <Title number="02">
            Viel zu <Highlight>entdecken</Highlight>
          </Title>
          <BodyText>
            Migipedia beherbergt eine breite Palette von Funktionen. Im Frontend
            spielen eine Vielzahl von Widgets aus der Migros-Bibliothek
            zusammen. Dazu kommen Recommender, die im Nu sinnvolle
            Produktempfehlungen liefern. Auch das Ausspielen von
            Community-Funktionen wie Bewerten und Tauschen an die Migros-App
            übernimmt Migipedia.
          </BodyText>
          <BodyText>
            <ImageContainer>
              <GatsbyImage fluid={logoCloud} />
            </ImageContainer>
          </BodyText>
          <div>
            <BodyText>
              Viele Funktionen sind als modular einsetzbare Widgets konzipiert,
              sodass sie problemlos auf anderen Plattformen der Migros
              eingesetzt werden können. Ein Rezept auf Migusto bewerten, eine
              Produktfrage in der Migros App stellen oder beantworten, einen
              Einkauf auf SportXX bewerten, an einem Produkttest auf Migros.ch
              teilnehmen oder im Forum auf Migipedia mitdiskutieren – die
              Community erstreckt sich heute über elf unterschiedliche digitale
              Touchpoints.
            </BodyText>
          </div>
          <div>
            <BodyText>
              Die neuen Recommender schlagen den Nutzern Produkte zum Bewerten
              vor und ziehen dafür verschiedene Datenquellen wie Einkäufe,
              Aktionen und Neuheiten zurate. Die Personalisierung bringt den
              Nutzern relevantere Empfehlungen und der Migros mehr Rückmeldungen
              – ein Gewinn für beide Seiten.
            </BodyText>
          </div>
        </Container>

        <Container>
          <Cumulus />
        </Container>

        <Container>
          <Title number="03">
            Solide Basis, <Highlight>stets ausbaubar</Highlight>
          </Title>
          <BodyText>
            Intelligentes Caching arbeitet im Hintergrund für ein gelungenes
            Nutzererlebnis. Einerseits sorgt Next.js dafür, dass nicht bei jedem
            Request bei null begonnen werden muss. Andererseits beherrscht auch
            die Reactions API, die hinter Migipedia steht, benutzerspezifisches
            Caching.
          </BodyText>
          <BodyText>
            Auf eine solide Infrastruktur lässt sich bauen: So verfügt das Forum
            über Cross Linking zu Produkten, die als solche erkannt und
            dargestellt werden. Und wer über den Fortgang einer Diskussion per
            E-Mail auf dem Laufenden gehalten werden will, kann ein Thema
            abonnieren und wird dann bei neuen Beiträgen sofort informiert.
            Dafür kommt RabbitMQ zum Einsatz.
          </BodyText>
        </Container>

        <ImageCircle
          images={{
            left: discussing,
            right: bike,
            center: reviewFlow,
          }}
        />

        <Container>
          <Title number="04">
            <Highlight>Verbinden</Highlight>, was zusammen gehört
          </Title>
          <BodyText>
            Die Migros kennt ihre Kundschaft ziemlich gut. Kauft immer Karotten,
            lebt auf einem Bauernhof, bevorzugt Bio. Diskutiert auf Migipedia
            als <Emphasis>rabid_rabbit_rabbi</Emphasis> meist über Heu und hat
            Fragen zu verschiedenen Gemüsen gestellt.
          </BodyText>
          <ReviewFlow />
          <BodyText>
            So kommen Daten aus diversen Quellen zusammen. Migipedia greift über
            eine Microservice-Architektur auf diese Daten zu und verwendet sie,
            um personalisierte Inhalte auszuliefern. Die Recommender werden
            nicht nur auf der Plattform selbst eingesetzt, sondern auch im
            Migros-Newsletter. Jede Woche wird 450 000 Abonnentinnen und
            Abonnenten ein Produkt zur Bewertung vorgeschlagen, das sie kürzlich
            gekauft, aber noch nicht bewertet haben.
          </BodyText>
          <BodyText>
            Andererseits ist Migipedia der Dreh- und Angelpunkt für
            Kundenbeiträge. Die Meinungen und Fragen fliessen in ein
            Datawarehouse und sind über Google Data Studio für Analysen
            zugänglich. Die Daten werden zu jeder vollen Stunde im Datastudio
            aktualisiert. So stehen für die Analyse immer die aktuellsten Daten
            zur Verfügung.
          </BodyText>
        </Container>

        <JuryNote />
        <Footer />
      </>
    </ThemeProvider>
  )
}
